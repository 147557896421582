import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { RichText } from 'prismic-reactjs'
import get from 'lodash/get'
import registry from '../../helpers/registry'
import Logo from '../../images/svg/logo-white.svg'
import './style.css'

const Footer = () => {
  const query = graphql`
    query FooterQuery {
      prismic {
        links: allSite_configurations {
          edges {
            node {
              footer_menu {
                page_link {
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Text_page {
                    title
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
        address: allContacts {
          edges {
            node {
              address
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={withPreview(({ prismic }) => {
        const { footer_menu } = get(prismic, 'links.edges[0].node')
        const { address } = get(prismic, 'address.edges[0].node')

        const addressFirstLine = [address[0]]

        return (
          <footer className="section footer">
            <Link className="footer__logo link-simple" to={registry.home}>
              <Logo className="footer__logo__icon" />
            </Link>
            {address && (
              <div className="footer__address">
                {RichText.render(addressFirstLine)}
              </div>
            )}
            <nav className="footer__menu">
              {footer_menu.map(link => (
                <span
                  className="footer__menu__item"
                  key={link.page_link._meta.uid}
                >
                  <Link
                    to={`/${link.page_link._meta.uid}`}
                    className="footer__menu__link"
                  >
                    {RichText.asText(link.page_link.title)}
                  </Link>
                </span>
              ))}
            </nav>
          </footer>
        )
      }, query)}
    />
  )
}

export default Footer
